import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'
import Donate from '../components/Donate'

// Export Template for use in CMS preview
export const ComponentsPageTemplate = ({
  title,
  section0,
  section1,
  body1,
  section2,
  body2,
  section3,
  body3,
  body,
  donatevalues
}) => (
  <main>
    <section className="section">
      <div className="container">
        <Content source={section0} />
      </div>

      <div className="container">
        <Content source={section1} />
        <h4><Content source={body1} /></h4>
      </div>

      <div className="container">
        <Content source={section2} />
      <h4><Content source={body2} /></h4>
      </div>

      <div className="container">
        <Content source={section3} />
      <h4><Content source={body3} /></h4>
      </div>
      <div className="container">
        <Content source={body} />
      </div>   
    </section>
  </main>
)

const ComponentsPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ComponentsPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ComponentsPage

export const pageQuery = graphql`
  query earlyvotepage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        template
        section0
        section1
        body1
        section2
        body2
        section3
        body3
        donatevalues
      }
    }
  }
`
